<!--
  ////////////////////////////////////////////////////////////////////////////////
  //
  // ⭐ DEFINE EL COMPONENTE DE VUE
  //
  ////////////////////////////////////////////////////////////////////////////////
-->
<script>
/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  dependencias
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
import { ref, computed } from 'vue'
import {validFormulas, validParties, 
        getDataByCasilla, getDataBySection, 
        parseExpenses, parseIncome, percent,
        parseEvents} from "@/common-library"
/// svg
  import SvgIcon from '@/components/SvgIcon';

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  datos
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
import theData from '@/assets/chiapas-data.json'
import theParties from "@/assets/chiapas-parties.json";
import resultsByFormula from "@/assets/chiapas-formulas.json";
import geojson from "@/assets/chiapas.json";

import Income from "@/assets/income-chiapas.json"
import Expenses from "@/assets/expenses-chiapas.json"
import Events from "@/assets/events-chiapas.json"

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  componentes
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
import Casilla from '@/components/CasillaTable';
import Seccion from "@/components/SeccionTable";
import Formula from "@/components/FormulaTable";
import MainMap from "@/components/Map";
import ExpTable from "@/components/ExpensesTable";
import IncTable from "@/components/IncomeTable";
import EveTable from "@/components/EventsTable";


/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  setup
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/

// const
const locationID  = 5; 

// data
const order       = ref("casilla");
const type        = ref("");
const campaign    = ref("campaña");
const meta        = ref("results");


// lists
const resumen     = resultsByFormula.find(d => d.ID_DISTRITO == locationID);
const sections    = [...new Set(theData.map(d => d.SECCION))];
const valid       = validParties(theParties, theData);
const formulas    = validFormulas(resumen, valid);
const casillaData = getDataByCasilla(theData, formulas);
const seccionData = getDataBySection(theData, formulas, sections);
const expenses    = parseExpenses(Expenses, formulas);
const expensesCam = expenses.filter(d => d.tipo_de_proceso == "CAMPAÑA");
const expensesPre = expenses.filter(d => d.tipo_de_proceso == "PRECAMPAÑA");
const income      = parseIncome(Income, formulas);
const incomeCam   = income.filter(d => d.tipo_de_proceso == "CAMPAÑA");
const incomePre   = income.filter(d => d.tipo_de_proceso == "PRECAMPAÑA");
const events      = parseEvents(Events, expenses, formulas);
const eventsCam   = events.filter(d => d.tipo_de_proceso == "CAMPAÑA");
const eventsPre    = events.filter(d => d.tipo_de_proceso == "PRECAMPAÑA");


// computed
const current     = computed( () => formulas.find( formula => formula.id == order.value ) );
const formulaData = computed( () => {
  if(!current.value) return;

  let items   = [...seccionData];
  for(let item of items){
    item.LISTA_NOMINAL_percent   = 100;
    item.NUM_VOTOS_NULOS_percent = percent( item.NUM_VOTOS_NULOS / item.LISTA_NOMINAL);
    item.TOTAL_VOTOS_percent     = percent(item.TOTAL_VOTOS / item.LISTA_NOMINAL);
    for(let party of formulas){
      item[party.id + '_percent'] = percent(item[party.id] / item.LISTA_NOMINAL);
      item[party.id + '_diff']    = percent(item[party.id] / item[current.value.id]);
    }
  }
  return items;
});


/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  VUE CODE
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
export default {
  setup(){
    return {
      // data
      order,
      type,
      campaign,
      meta,
      // static
      resumen,
      formulas,
      casillaData,
      seccionData,
      geojson,
      Expenses,
      expensesCam,
      expensesPre,
      Income,
      incomeCam,
      incomePre,
      eventsCam,
      eventsPre,
      Events,
      // computed
      current,
      formulaData,
      // methods
      percent
    }
  },

  components : {
    Casilla,
    Seccion,
    Formula,
    MainMap,
    ExpTable,
    SvgIcon,
    IncTable,
    EveTable
  }
}
</script>

<template>
  <div>
    <!-- HEAD -->
    <div class="container mt-4 pt-4">
      <div class="if_resume_election">
        <div class="row mt-3 pt-2">
          <div class="col-12">
            <h6 class="text-center if_election">Elección 2018</h6>
            <div class="separator"></div>
          </div>
        </div>
        <div class="row mt-1 pt-1">
          <div class="col-sm-4">
            <h6 class="mb-0 pb-0"><svg-icon icon="entidad" :hasFill="true"></svg-icon> Entidad Federativa: ({{resumen.ID_ESTADO}}) <span class="if_title-content">{{resumen.NOMBRE_ESTADO}}</span> </h6>
          </div>
          <div class="col-sm-4 text-center">
            <h6 class="mb-0 pb-0"><svg-icon icon="cargo" :hasFill="true"></svg-icon> Cargo<span class="if_title-content">Diputado federal MR</span> </h6>
          </div>
          <div class="col-sm-4 text-right">
            <h6 class="mb-0 pb-0"><svg-icon icon="ambito" :hasFill="true"></svg-icon> ÁMBITO <span class="if_title-content">Federal</span> </h6>
          </div>
        </div>
        <div class="row mt-3 pt-3">
          <div class="col-12">
            <h6 class="mb-0 pb-0"><svg-icon icon="entidad" :hasFill="true"></svg-icon> Distrito: {{resumen.ID_DISTRITO}}</h6>
            <h1 class="mt-0 mt-0 pt-0 pb-4">{{resumen.MUNICIPIO}}</h1>
            <div class="separator"></div>
          </div>
        </div>
      </div>

      <div class="row if_resume_data">
        <!-- casillas-->
        <div class="col text-center">   
          <p> <svg-icon icon="casillas_1" :hasFill="true"></svg-icon> casillas</p>
          <h3> {{resumen.CASILLAS}}</h3> 
        </div>
        <!-- secciones-->
        <div class="col text-center">  
         <p> <svg-icon icon="secciones" :hasFill="true"></svg-icon> secciones</p> 
          <h3> {{resumen.SECCIONES}}</h3> 
        </div>
        <!-- Participación-->
        <div class="col text-center">   
          <p> <svg-icon icon="votos" :hasFill="true"></svg-icon> Participación</p>
          <h3>{{ percent(resumen.TOTAL_VOTOS/resumen.LISTA_NOMINAL)}}%</h3>      
        </div>
        <!-- votos-->
        <div class="col text-center">   
          <p> <svg-icon icon="votos" :hasFill="true"></svg-icon> votos</p>
          <h3> {{resumen.TOTAL_VOTOS}}</h3>      
          <div class="el_conteiner_graph">
            <div
              class="el_table_graph"
              :style="{ width: ((resumen.TOTAL_VOTOS*100)/resumen.LISTA_NOMINAL) + '%' }"
            ></div>
          </div>
        </div>
        <!-- votos nulos-->
        <div class="col text-center">   
          <p> <svg-icon icon="votos_nulos" :hasFill="true"></svg-icon> votos nulos</p>
          <h3> {{resumen.NUM_VOTOS_NULOS}}</h3>     
          <div class="el_conteiner_graph">
            <div
              class="el_table_graph"
              :style="{ width: ((resumen.NUM_VOTOS_NULOS*100)/resumen.TOTAL_VOTOS) + '%' }"
            ></div>
          </div>    
        </div>
        <!-- votos candidatos no registrados-->
        <div class="col text-center">   
          <p> <svg-icon icon="votoscandidatosnoregistrados" :hasFill="true"></svg-icon> votos CNR*</p>
          <h3> {{resumen.NUM_VOTOS_CAN_NREG}}</h3>    
          <div class="el_conteiner_graph">
            <div
              class="el_table_graph"
              :style="{ width: ((resumen.NUM_VOTOS_CAN_NREG*100)/resumen.TOTAL_VOTOS) + '%' }"
            ></div>
          </div>    
        </div>
        <!-- acta-->
        <div class="col text-center">   
          <h3> <svg-icon icon="acta" :hasFill="true"></svg-icon> </h3> 
          <p>  <a :href="resumen.RUTA_ACTA" target="_new">Acta</a> </p>
        </div>

      </div>
      <div class="row">
        <div class="col-12">
          <div class="separator mt-4 pb-3 mb-2"></div>
          <p class="el_notevotes text-right">* Candidatos no registrados</p>
        </div>
      </div>
    </div>

     <!-- TABS-->
     <div class="container">
     <div class="row">
      <div class="col">
        <a :class="{btn_if_close : true, current : meta == 'results'}" v-on:click.prevent="meta ='results'">Resultados</a>
      </div>
      <div class="col">
        <a :class="{btn_if_close : true, current : meta == 'expenses'}" v-on:click.prevent="meta ='expenses'">Gastos</a>
      </div>
      <div class="col">
        <a :class="{btn_if_close : true, current : meta == 'income'}" v-on:click.prevent="meta ='income'">Ingresos</a>
      </div>
      <div class="col">
        <a :class="{btn_if_close : true, current : meta == 'events'}" v-on:click.prevent="meta ='events'">Eventos</a>
      </div>
     </div>
     </div>

    <!-- META DATA SELECTOR -->
    <div class="container" v-if="meta != 'results'">
      <form>
        <div class="row mt-4 pt-4">
          <div class="col-sm-6">
          <p>
            <strong>Mostrar información de: </strong>
            <select v-model="campaign">
              <option value="campaña">Campaña</option>
              <option value="precampaña">Precampaña</option>
            </select>
          </p>
        </div>
        
        <!-- <div class="col-sm-6">
          <p>
            <strong>Selecciona la información: </strong>
            <select v-model="meta">
              <option value="expenses">Gastos</option>
              <option value="income">Ingresos</option>
              <option value="events">Eventos</option>
            </select>
          </p>
        </div> -->
        </div>
      </form>
    </div>
    
    <div class="container" v-if="meta != 'results'">
      <div class="table_overflow">
        <exp-table v-if="meta=='expenses'" :items="campaign == 'campaña' ? expensesCam : expensesPre" />
        <inc-table v-if="meta=='income'" :items="campaign == 'campaña' ? incomeCam : incomePre" />
        <eve-table v-if="meta=='events'" :items="campaign == 'campaña' ? eventsCam : eventsPre" />
      </div>
    </div>

    <main-map v-if="meta == 'results'" :geojson="geojson" :items="seccionData" :order="order" :type="type" :formulas="formulas" />


    <!-- TABLE SELECTOR -->
    <div class="container" v-if="meta == 'results'">
      <form>
        <div class="row mt-4 pt-4">
          <div class="col-sm-6">
        <p>
        <strong>Ordenar datos por: </strong>
        <select v-model="order">
          <option value="casilla">Casilla</option>
          <option value="seccion">seccion</option>
          <option v-for="(it, i) in formulas " :value="it.id" :key="`${i}-opt`">
            {{it.name}}
          </option>
        </select>
        </p>
        </div>
        <div class="col-sm-6">
        <p v-if="order != 'casilla' & order!= 'seccion' ">
          <strong>Mostrar información del partido/coalición por: </strong>
          <select v-model="type">
            <option value="">Votos obtenidos</option>
            <option value="_percent">Porcentaje con respecto a lista nominal</option>
            <!-- <option value="_diff">Diferencia respecto a los demás partidos</option> -->
          </select>
        </p>
        </div>
        </div>
      </form>
    </div>

    <!-- TABLA X CASILLAS -->
    <div class="container" v-if="order == 'casilla' && meta == 'results'">
      <div>
        <p class="note text-right mb-0"><a href="https://portalanterior.ine.mx/archivos2/Alterna/2016/PREP/CentroDeAyuda/Extraordinaria/rsc/pdf/tipos_casillas.pdf">Tipo de Casillas</a>. <strong>B</strong>: Casilla Básica / <strong>C</strong>: Casilla Contigua / <strong>E</strong>: Casilla Extraordinaria / <strong>S</strong>: Casilla Especial </p>
      </div>
      <div class="table_overflow">
        <casilla :parties="formulas" :data="casillaData" :resumen="resumen" />
      </div>
    </div>

    <!-- TABLA X SECCIÓN -->
    <div class="container" v-else-if="order == 'seccion' && meta == 'results'">
      <div class="table_overflow">
        <seccion :parties="formulas" :data="seccionData" :resumen="resumen" />
      </div>
    </div>

    <!-- TABLA X FÓRMULA -->
    <div class="container" v-else-if="meta == 'results'">
      <div class="table_overflow">
        <formula :parties="formulas" :data="formulaData" :resumen="resumen" :order="order" :type="type" />
      </div>
    </div>
  </div>
</template>